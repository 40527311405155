import Analytics from '@hh.ru/analytics-js';
import { AnalyticsParams } from '@hh.ru/analytics-js/lib/hhAnalytics';
import urlParser from 'bloko/common/urlParser';

import getOpenedTabCount from 'HHC/Performance/TabCounter';

/* Метрики, которые отправляем батчем, они происходят на каждую загрузку страницы */
const FIRST_BATCH = [
    'fcp',
    'lux-init-time',
    'lux-first-render-time',
    'lux-init-time-from-nav-start',
    'classic-components-init-time',
    'ttfb',
    'bff_xhh_time',
    'network_xhh_time',
];

interface Metric {
    metric_name: string;
    metric_value: number;
    device_memory: number;
    hardware_concurrency: number;
    effective_type?: string;
    rtt?: number;
    downlink?: number;
    service_name: string;
    client_dt: number;
    params?: Record<string, string>;
}

interface LegacyMetric {
    metricName: string;
    value: number;
    deviceMemory?: number;
    hardwareConcurrency?: number;
    effectiveType?: string;
    rtt?: number;
    downlink?: number;
}

let notSendedMetrics: Metric[] = [];
let needSendToNewKafkaCluster = false;

const sendPostRequest = async function (event: string, bodyData: AnalyticsParams = {}): Promise<void | Response> {
    const body = JSON.stringify(bodyData);

    const parsedURL = urlParser('/analytics');
    parsedURL.params = {
        ...(window?.globalVars?.analyticsParams as Record<string, string>),
        event,
        originalRequestId: window?.globalVars?.requestId,
    };

    try {
        const headers = {
            type: 'application/json',
        };
        const blob = new Blob([body], headers);
        const sendStatus = navigator.sendBeacon(parsedURL.href, blob);
        if (sendStatus) {
            return await Promise.resolve();
        }
    } catch (_ignore) {} // eslint-disable-line
    return fetch(parsedURL.href, { method: 'POST', body }).catch(console.error);
};

const convertToAnalytics = (metricName: string, value: number): [LegacyMetric, Metric] => {
    const metric = {
        metricName,
        value,
        deviceMemory: (navigator as unknown as { deviceMemory: number })?.deviceMemory,
        hardwareConcurrency: navigator?.hardwareConcurrency,
        effectiveType: navigator?.connection?.effectiveType,
        rtt: navigator?.connection?.rtt,
        downlink: navigator?.connection?.downlink,
    };
    return [
        metric,
        {
            metric_name: metricName,
            metric_value: value,
            device_memory: metric.deviceMemory,
            hardware_concurrency: metric.hardwareConcurrency,
            effective_type: metric.effectiveType,
            rtt: metric.rtt,
            downlink: metric.downlink,
            service_name: 'xhh',
            client_dt: Date.now(),
            params: {
                openedTabCount: `${getOpenedTabCount()}`,
                visibilityState: document.visibilityState,
            },
        },
    ];
};

export const sendBatchAnalytics = (metrics: { metricName: string; value: number }[]) => {
    const metricsForSend = metrics.map((metric) => {
        const [_, webPerformanceMetric] = convertToAnalytics(metric.metricName, metric.value);
        return webPerformanceMetric;
    });

    if (window.globalVars.features.use_new_way_to_send_performance_metric) {
        void sendPostRequest('web_performance', { metrics: metricsForSend });
    }
};

export default (metricName: string, value: number, sendToSiteAnalytics = false) => {
    const [metric, webPerformanceMetric] = convertToAnalytics(metricName, value);

    if (sendToSiteAnalytics) {
        Analytics.sendHHEvent('xhh_performance_metrics', metric);
    }

    notSendedMetrics.push(webPerformanceMetric);

    const notSendedMetricsName = notSendedMetrics.map((metric) => metric.metric_name);

    if (
        notSendedMetrics.length >= FIRST_BATCH.length &&
        FIRST_BATCH.every((metricName) => notSendedMetricsName.includes(metricName))
    ) {
        needSendToNewKafkaCluster = true;
    }

    if (window.globalVars.features.use_new_way_to_send_performance_metric && needSendToNewKafkaCluster) {
        const data = [...notSendedMetrics];
        notSendedMetrics = [];

        const result = { metrics: data.map((metric) => metric) };
        void sendPostRequest('web_performance', result);
    }
};
