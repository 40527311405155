import Storage from 'bloko/common/storage/LocalStorageWrapper';

const LS_ID = 'HH-TAB-COUNT';

window.onload = function () {
    const currentTabCount = localStorage.getItem(LS_ID);
    if (!currentTabCount) {
        Storage.setItem(LS_ID, '1');
    } else {
        Storage.setItem(LS_ID, `${parseInt(currentTabCount, 10) + 1}`);
    }
};

window.addEventListener('beforeunload', () => {
    const currentTabCount = Storage.getItem(LS_ID);

    if (currentTabCount) {
        Storage.setItem(LS_ID, `${parseInt(currentTabCount, 10) - 1}`);
    }
});

export default () => {
    const currentTabCount = Storage.getItem(LS_ID);
    return currentTabCount ? parseInt(currentTabCount, 10) : 1;
};
